<template>
    <ele-dialog
      :isVisible="isVisible"
      :dialogTitle="dialogTitle"
      @on-add="sava(elForms)"
      @on-cancel="$emit('closeQueryLogEd')"
      :dialogWidth="'35.5rem'"
    >
      <el-form
        ref="elForms"
        :model="logForm"
        label-width="80px"
        :rules="logFormRules"
        label-position="left"
      >
        <div>
          <div>
            <!-- <el-form-item label="t('164')" prop="airport">
                <el-select v-model="logForm.airport" placeholder="t('175')" size="small">
                    <el-option
                    v-for="item in typeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                    />
                </el-select>
            </el-form-item> -->
            <el-form-item :label="t('594')" prop="type">
                <el-select v-model="logForm.type" class="m-2" :placeholder="t('175')" size="small">
                    <el-option
                    v-for="item in typeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                    />
                </el-select>
            </el-form-item>
            <el-form-item :label="t('1346')" prop="time">
                <el-date-picker
                  v-model="logForm.time"
                  type="daterange"
                  value-format="YYYY-MM-DD"
                  :unlink-panels='true'
                  :range-separator="t('1347')"
                  :start-placeholder="t('1348')"
                  :disabled-date="disabledDate"
                  :end-placeholder="t('1348')"
                />
            </el-form-item>
          </div>
        </div>
      </el-form>
    </ele-dialog>
  </template>

  <script>
import { t } from '../../../../languages';
  import {
    defineComponent,
    onMounted,
    reactive,
    toRefs,
    watch,
    ref,
    nextTick
  } from "vue";
  import members from "@/network/member";
  import device from "@/network/device";
  import { ElMessage } from "element-plus";
  export default defineComponent({
    props: {
      isVisible: {
        type: Boolean,
        default: false
      },
      dialogTitle: {
        type: String,
        default: () => {}
      },
    },
    emits: ["handleQueryLogEd",'closeQueryLogEd'],
    setup(props, { emit }) {
      const { dialogTitle } = toRefs(props);


      const state = reactive({
        logForm: {
          time:[],
          type:'0',
        },
        typeList:[
        {
            value: '0',
            label: t('164'),
        },
        {
            value: '1',
            label: t('595'),
        },
        {
            value: '2',
            label: t('596'),
        },
        ],
        logFormRules: {
          type: [
            { required: true, message: t("1349"), trigger: "change" }
          ],
          time: [
            { required: true, message: t("1350"), trigger: "change" }
          ],
        },
      });

      onMounted(() => {
        //获取当前时间
        methods.getTime()
        //获取类型列表
        methods.queryTypeList()
      });
      const disabledDate = (time) => {
        return time.getTime() > Date.now();
    }
      const elForms = ref(null);
      const methods = reactive({
        //封装一个获取当前年月日的函数getTime
      getTime(date) {
          let y = new Date().getFullYear() //年
          let m = new Date().getMonth() + 1  //月，月是从0开始的所以+1
          let d = new Date().getDate() //日
          m = m < 10 ? "0" + m : m //小于10补0
          d = d < 10 ? "0" + d : d //小于10补0
          nextTick(()=>{
          state.logForm.time[0] = methods.getNextDate(y + "-" + m + "-" + d,2)
          state.logForm.time[1] = y + "-" + m + "-" + d;
          })

        },
        getNextDate(date,day) {  
          var dd = new Date(date);
          dd.setDate(dd.getDate() - day);
          var y = dd.getFullYear();
          var m = dd.getMonth() + 1 < 10 ? "0" + (dd.getMonth() + 1) : dd.getMonth() + 1;
          var d = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate();
          return y + "-" + m + "-" + d;
        },
        //获取类型列表
        queryTypeList: async () => {
          return
          let res = await device.delStreaming({ ids });
          if (res.resultStatus) {
          
          }
        },
        //比较两个日期的大小
        dateChangeStar:()=>{
        var starttimes = state.logForm.timeStar.split("-");
        var endtimes = state.logForm.timeEnd.split("-");
        var starttimeTemp = starttimes[0] + "/" + starttimes[1] + "/" + starttimes[2];
        var endtimesTemp = endtimes[0] + "/" + endtimes[1] + "/" + endtimes[2];
        if(Date.parse(new   Date(starttimeTemp))>Date.parse(new   Date(endtimesTemp))) {
          ElMessage.info(t('1351'))
          state.logForm.timeStar = ''
        }
        },
        //比较两个日期的大小
        dateChangeEnd:()=>{
        var starttimes = state.logForm.timeStar.split("-");
        var endtimes = state.logForm.timeEnd.split("-");
        var starttimeTemp = starttimes[0] + "/" + starttimes[1] + "/" + starttimes[2];
        var endtimesTemp = endtimes[0] + "/" + endtimes[1] + "/" + endtimes[2];
        if(Date.parse(new   Date(starttimeTemp))>Date.parse(new   Date(endtimesTemp))) {
          ElMessage.info(t('1352'))
          state.logForm.timeEnd = ''
        }
        },
        sava: () => {
          elForms.value.validate(async valid => {
            if (valid) {
              emit("handleQueryLogEd",state.logForm);
            } else {
              return false;
            }
          });
        },
      });

      return {
        ...toRefs(state),
        ...toRefs(methods),
        elForms,
        disabledDate
      };
    }
  });
  </script>

  <style lang="less" scoped>
    /deep/ .el-date-editor .el-range-input{
      color: #fff;
    }
  /deep/.el-date-editor{
    border: 1px solid #36e5ff;
      border-radius: 0.25rem;
      background-color: #001c31;
      height: 2.5rem;
      box-shadow: 0 0 0 0;
      color: #fff;
      .el-input__inner {
        color: #fff;
      }
  }
  /deep/.el-date-editor:hover{
    border: 1px solid #36e5ff;
      border-radius: 0.25rem;
      background-color: #001c31;
      height: 2.5rem;
      box-shadow: 0 0 0 0;
      color: #fff;
      .el-input__inner {
        color: #fff;
      }
  }
  /deep/.el-input__wrapper {
      border: 1px solid #36e5ff;
      border-radius: 0.25rem;
      background-color: @lightBackground;
      height: 2.5rem;
      box-shadow: 0 0 0 0;
      .el-input__inner {
        color: #fff;
      }
    }
    /deep/ .el-date-editor.el-input, .el-date-editor.el-input__inner{
        width: auto;
    }
    /deep/ .el-date-editor .el-range-separator{
        color: #fff;
    }
  .el-select {
    width: 100%;
    /deep/.el-input__wrapper {
      width: 18.5rem;
      border: 1px solid #36e5ff;
      border-radius: 0.25rem;
      background-color: @lightBackground;
      height: 2.5rem;
      box-shadow: 0 0 0 0;
      .el-input__inner {
        color: #fff;
      }
    }
  }
  /deep/.el-dialog__header .el-dialog__title {
    color: #ffffff !important;
  }
  /deep/.el-form-item {
    margin-bottom: 2rem;
  }
  /deep/.el-form-item .el-form-item__label {
    margin-bottom: 0.25rem !important;
    color: #ffffff;
  }
  /deep/.el-dialog__close {
    color: #ffffff !important;
  }
  /deep/.el-form--default.el-form--label-top .el-form-item .el-form-item__label {
    margin-bottom: 0;
  }
  .editbox {
    margin-bottom: 2.5rem;
  }
  .left {
    width: 20rem;
  }
  .right {
    width: 20rem;
  }
  /deep/.common-input .el-input__wrapper {
    background: #001c31 !important;
  }
  /deep/ .el-input__wrapper {
    background: #001c31 !important;
    height: 2.5rem !important;
    border-radius: 0 !important;
  }
  /deep/ .el-input__inner {
    color: #ffffff;
    height: 2.5rem !important;
  }
  .select {
    position: relative;
    width: 100%;
    height: 2.5rem;
    background: #001c31;
    box-sizing: border-box;
    border: 1px solid #51d2ff;
    // border-radius: 4px;
    padding: 0.8125rem 0.75rem;
    span {
      color: #a9b8c3;
    }
    .active_span {
      color: #ffffff;
    }
    .select_icon {
      color: #a9b8c3;
    }
    ul {
      position: absolute;
      top: 2.5rem;
      left: 0;
      width: 100%;
      height: 10.75rem;
      background: #ffffff;
      border-radius: 0.25rem;
      z-index: 33;
      .libox {
        width: 100%;
        height: 10.6875rem;
        overflow: hidden;
        overflow-y: auto;
      }
      li {
        height: 2.1875rem;
        line-height: 2.1875rem;
        padding: 0.3125rem 0.6875rem 0.1875rem 1.875rem;
        color: #666666;
        cursor: pointer;
        box-sizing: border-box;
      }
      .active {
        color: #138fcf;
      }
      .in_border {
        box-shadow: 0 0 0 1px #138fcf inset;
        // border: .0625rem solid #138FCF;
      }
      input {
        height: 100%;
        width: 12.5rem;
        outline: 0;
        box-sizing: border-box;
        color: #333333;
        border: 0;
        background: #ffffff;
      }
      input::-webkit-input-placeholder {
        color: #a9b8c3;
      }
      .addbox {
        padding: 0.3125rem 0 0.3125rem 0;
        .add_right {
          font-size: 0.75rem;
          width: 3.5rem;
          height: 2.1875rem;
          line-height: 2.1875rem;
          text-align: center;
          color: #138fcf;
          z-index: 22;
          margin-right: 0.3125rem;
          cursor: pointer;
        }
      }
      .addshow {
        width: 100%;
        margin-left: 1.875rem;
        height: 2.1875rem;
        line-height: 2.1875rem;
        padding: 0 0 0 0.3125rem;
        z-index: 22;
        box-sizing: border-box;
        // border-radius: 0 !important;
      }
      .select_edit {
        font-size: 0.9375rem;
        color: #138fcf;
        margin-right: 1.1875rem;
      }
      .element_icon {
        font-size: 0.9375rem;
        color: red;
      }
      .select_btn {
        width: 100%;
        height: 40px;
        background: #138fcf;
        border-radius: 0px 0px 4px 4px;
        img {
          height: 1.125rem;
          width: 1.125rem;
          margin-right: 0.4375rem;
        }
        .select_add {
          background: #ffffff;
        }
      }
    }
  }
  /deep/ .el-input--small .el-input__wrapper{
    padding: 1px 10px;
    
  }
  </style>
  