import { t } from '../languages';
import service from "./service";
import request from "./http.js";
const platforms = {
    // 获取平台对接授权信息
    getPlatforms: (sn) => {
        return service.get({
            url: `/api/v1/zsj/query/reportNestInfo/${sn}`,
        })
    },
    // 推送机场信息
    transmissionPlatforms: (sn) => {
        return service.post({
            url: `/api/v1/zsj/add/reportDroneList/${sn}`,
        })
    },
    // 移除推送机场信息
    removePlatforms: (sn) => {
        return service.post({
            url: `/api/v1/zsj/del/reportDroneList/${sn}`,
        })
    },
    
    // 自动授权
    authPlatforms: (isOpen,sn) => {
        return request({
            url: `/api/v1/zsj/isOpenAutomaticAuth/${sn}`,
            method: "post",
            params: {
                isOpen: isOpen,
            }
        });
    },
    // 起飞权限和控制权限
    flyControlPlatforms: (data) => {
        return service.post({
            url: `/api/v1/zsj/rememberUavOperationAuth`,
            data,
        })
    },
    // 指挥中心获取授权信息
    getCommandcenter: (sn) => {
        return service.get({
            url: `/api/v1/zsj/query/authInfo/${sn}`,
        })
    },
    // 指挥中心取消通过授权
    authCommandcenter: (data,sn) => {
        return service.post({
            url: `/api/v1/zsj/set/auth/${sn}`,
            data,
        })
    },

}
export default platforms;