<template>
  <div class="auth_dialog_box">
    <el-dialog
      v-model="visible"
      :width="'295px'"
      :custom-class="isCenter ? 'center_dialog common-dialog' : 'common-dialog'"
      :append-to-body="false"
      lock-scroll
      :top="'35Vh'"
      destroy-on-close
      ref="eleDialog"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <template #title>
        <div class="my-header">
          <span class="flex-fac">{{ t('平台对接') }}</span
          ><span
            class="iconfont icon-guanbi1 close hover_opacity"
            @click="onCancel"
          ></span>
        </div>
      </template>
      <div class="auth-content">
        <div class="auth-msg flex-sb">
          {{ t('推送机场信息') }}
          <el-switch
            v-model="isInformation"
            size="small"
            style="
              --el-switch-on-color: #52ef40;
              --el-switch-off-color: #707275;
              margin-left: 14px;
            "
            @change="informationChange"
          />
        </div>
        <div class="auth-item auth-item-top flex-sb">
          {{ t('无人机起飞自动授权') }}
          <el-switch
            v-model="isauthorisationVal"
            size="small"
            style="
              --el-switch-on-color: #52ef40;
              --el-switch-off-color: #707275;
              margin-left: 14px;
            "
            @change="flyChange"
          />
        </div>
        <div class="auth-item auth-item20-bottom" :class="isauthorisationVal?'':'mask'">
           <el-radio-group v-model="authorisation" @change="flyChange">
            <el-radio :label="true">{{ t('通过授权') }}</el-radio>
            <el-radio :label="false">{{ t('拒绝授权') }}</el-radio>
          </el-radio-group>
        </div>
        <div class="auth-item flex-sb">
          {{ t('无人机控制自动授权') }}
          <el-switch
            v-model="iscontrolVal"
            size="small"
            style="
              --el-switch-on-color: #52ef40;
              --el-switch-off-color: #707275;
              margin-left: 14px;
            "
            @change="controlChange"
          />
        </div>
        <div class="auth-item" :class="isauthorisationVal&&authorisation?'':'mask'">
           <el-radio-group v-model="controlAuth" @change="controlChange">
            <el-radio :label="true">{{ t('通过授权') }}</el-radio>
            <el-radio :label="false">{{ t('拒绝授权') }}</el-radio>
          </el-radio-group>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { t } from '../../../languages';
import {
  defineComponent,
  ref,
  toRefs,
} from "vue";
import platforms from "@/network/platforms"
export default defineComponent({
  props: {
    isVisible: {
      type: Boolean,
      default: false
    },
    authSn: {
      type: String,
      default: () => {}
    }
  },
  emits: ["closeAuth"],
  setup(props, { emit }) {
    const {isVisible,authSn} = toRefs(props);
    const visible = ref(false);
    visible.value = isVisible.value;
    const isInformation = ref(false);
    const automaticAuth = ref(false);
    const authorisation = ref(false);
    const controlAuth = ref(false);
    const isauthorisationVal = ref(false);
    const iscontrolVal = ref(false);

    /**
     * @description: 获取授权信息
     * @return {*}
     */    
    async function getPlatforms() {
      const res = await platforms.getPlatforms(authSn.value);
      if(res.resultStatus){
        automaticAuth.value = res.resultData.isAuth;
        authorisation.value = res.resultData.authFlyVal;  
        controlAuth.value = res.resultData.authUavControlVal;
        isInformation.value = res.resultData.isReport;
        isauthorisationVal.value = res.resultData.isAuthFly;
        iscontrolVal.value = res.resultData.isAuthUavControl;
      }
    }
    getPlatforms();
    
    /**
     * @description: 推送机场信息
     * @param {*} 
     * @return {*}
     */    
    async function informationChange(val) {
      if(val){
        const res = await platforms.transmissionPlatforms(authSn.value);
        if(res.resultStatus){
        }
      }else{
        const res = await platforms.removePlatforms(authSn.value);
        if(res.resultStatus){
        }
      }
    }

    /**
     * @description: 起飞授权
     * @param {*} 
     * @return {*}
     */    
    async function flyChange(val) {
      let flyObj = {
        isRemember:isauthorisationVal.value,
        nestSn:authSn.value,
        type:0,
        value:authorisation.value
      }
      const res = await platforms.flyControlPlatforms(flyObj);
      if(res.resultStatus){
      }
    }
    /**
     * @description: 控制授权
     * @param {*} 
     * @return {*}
     */    
    async function controlChange() {
        let controlObj = {
        isRemember:iscontrolVal.value,
        nestSn:authSn.value,
        type:1,
        value:controlAuth.value
      }
      const res = await platforms.flyControlPlatforms(controlObj);
      if(res.resultStatus){
      }
    }

    function onCancel() {
        visible.value = false;
        emit('closeAuth');
    }
    return {
        visible,
        isInformation,
        automaticAuth,
        authorisation,
        controlAuth,
        isauthorisationVal,
        iscontrolVal,
        informationChange,
        flyChange,
        controlChange,
        onCancel
    };
  }
});
</script>

<style lang="less" scoped>
.auth_dialog_box :deep(.common-dialog)  {
  background: rgba(11,79,122,0.9);

  .el-dialog__header {
    height: 2.4375rem;
    padding: .6875rem 1.25rem;
    border-bottom: 1px solid #077796;
    margin-right: 0;
    background: rgba(35,163,236,0.4);
    text-align: center;
    .el-dialog__title {
      font-size: 1.125rem;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #fff;
    }
  }
  .el-dialog__body {
    padding: 0 0 .875rem 0;
    color: #fff;
  }
}

/* 居中弹窗 */
.auth_dialog_box /deep/ .center_dialog {
  margin-top: 0 !important;
  top: 50%;
  transform: translateY(-50%);
}
.my-header{
  height: 100%;
  line-height: .8125rem;
  position: relative;
  .close{
    position: absolute;
    top: 0;
    right: 0;
    width: 1.5rem;
    font-size: 1.125rem;
  }
}
.auth-content{
  .auth-msg{
    height: 2.6875rem;
    line-height: 2.0625rem;
    padding: 0 1.125rem;
    border-bottom: 1px solid #136ca1;
  }
  .auth-item{
    height: 1.625rem;
    padding: 0 1.125rem;
    position: relative;
    /deep/.el-radio{
      margin-left: 12px;
      .el-radio__label{
      color: #fff !important;   //设置未被选中时文字的颜色
      font-size: 12px;
      }
      .el-radio__input{
        &.is-checked {     //被选中时
        .el-radio__inner{   //被选中时边框和背景的颜色
            background-color:#11BBFA;
            border-color:#11BBFA;
          }
 
        }
       .el-radio__inner{
            &:hover{
                border-color:#11BBFA;  //悬停时边框的颜色
            }
        }
      }
      .el-radio__input.is-checked + .el-radio__label{  //设置被选中时文字的颜色，直接写在这是没有效果的，一定要写在外面
        color: #11BBFA;
      }
    }
    /deep/.el-radio__input.is-checked + .el-radio__label {  //设置被选中时文字的颜色，这样才是有效的写法
      color: #fff !important;
      font-size: 12px;
    }
         
  }
  .auth-item-top{
    margin-top: .5625rem;
  }
  .auth-item-bottom{
    margin-bottom: .3125rem;
  }
  .auth-item20-bottom{
    margin-bottom: 1.25rem;
  }
  /deep/.el-switch__core {
    width: 2.125rem;
  }
}
.mask{
  height: 32px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  /deep/.el-radio{
    opacity: 0.6;
  }
}
</style>