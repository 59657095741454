<template>
  <ele-dialog
    :isVisible="isVisible"
    :dialogTitle="dialogTitle"
    @on-add="sava(elForms)"
    @on-cancel="$emit('handleStreamingEd')"
    :dialogWidth="'48.5rem'"
  >
    <el-form
      ref="elForms"
      :model="streamingForm"
      label-width="80px"
      :rules="streamingRules"
      label-position="top"
    >
      <div>
          <el-form-item :label="t('1147')" prop="liveName">
            <el-input
              class="common-input"
              v-model="streamingForm.liveName"
              @keyup="streamingForm.liveName = streamingForm.liveName.replace(/[ ]/g, '')"
              :placeholder="t('1353')"
              maxlength="32"
            ></el-input>
          </el-form-item>
      </div>

      <div>
        <el-form-item :label="t('1354')" prop="liveRtmpPath">
          <el-input
            class="common-input"
            v-model.trim="streamingForm.liveRtmpPath"
            @blur="pushFlowChange(streamingForm.liveRtmpPath)"
            @keyup="streamingForm.liveRtmpPath = streamingForm.liveRtmpPath.replace(/[ ]/g, '')"
            :placeholder="t('1355')"
            maxlength="253"
          ></el-input>
          <span class="sampe">{{ t('1356') }}:rtmp://pattern/live/123456</span>
        </el-form-item>
      </div>
      <div>
        <el-form-item :label="t('1357')" prop="playPath">
          <el-input
            class="common-input"
            v-model.trim="streamingForm.playPath"
            @keyup="streamingForm.playPath = streamingForm.playPath.replace(/[ ]/g, '')"
            :placeholder="t('1358')"
            maxlength="253"
          ></el-input>
          <span class="sampe">{{ t('1356') }}:https://pattern/live/123456.live.flv</span>
        </el-form-item>
      </div>
    </el-form>
  </ele-dialog>
</template>

<script>
import { t } from '../../../../languages';
import {
  defineComponent,
  onMounted,
  reactive,
  toRefs,
  watch,
  ref,
  nextTick
} from "vue";
import members from "@/network/member";
import device from "@/network/device";
import { ElMessage } from "element-plus";
export default defineComponent({
  props: {
    isVisible: {
      type: Boolean,
      default: false
    },
    dialogTitle: {
      type: String,
      default: () => {}
    },
    airPortSn: {
      type: String,
      default: () => {}
    },
    rowData: {
      type: Object
    }
  },
  emits: ["handleStreamingEd"],
  setup(props, { emit }) {
    const { dialogTitle, rowData, airPortSn } = toRefs(props);
    const state = reactive({
      streamingForm: {
        sn: "",
        liveName: "",
        liveRtmpPath: "",
        playPath: ""
      },
      streamingRules: {
        liveRtmpPath: [
          { required: true, message: t("1355"), trigger: "change" },
          // {
          //   validator: function (rule, value, callback) { 
          //     var RTMP = "rtmp://";
          //     var IPV4_PATTERN_NOT_START_AND_END = "([1-9]|[1-9]\\d|1\\d{2}|2[0-4]\\d|25[0-5])(\\.(\\d|[1-9]\\d|1\\d{2}|2[0-4]\\d|25[0-5])){3}";
          //     var URL_PATTERN = "((((25[0-5])|(2[0-4]\\d)|(1\\d{2})|([1-9]?\\d)\\.){3}((25[0-5])|(2[0-4]\\d)|(1\\d{2})|([1-9]?\\d)))|(([\\w-]+\\.)+(net|com|org|gov|edu|mil|info|travel|pro|museum|biz|[a-z]{2})))";
          //     var LETTER_AND_NUMBER_PATTERN_NOT_START_AND_END = "([A-Za-z0-9]{1,255})";
          //     var PORT_PATTERN = "([0-9]|[1-9]\\d{1,3}|[1-5]\\d{4}|6[0-4]\\d{3}|65[0-4]\\d{2}|655[0-2]\\d|6553[0-5])"; 
          //     var LIVE_URL = "(" + IPV4_PATTERN_NOT_START_AND_END + "|" + URL_PATTERN + ")(:" + PORT_PATTERN + ")?(/jessica)?/live/" + LETTER_AND_NUMBER_PATTERN_NOT_START_AND_END + "_" + LETTER_AND_NUMBER_PATTERN_NOT_START_AND_END;
          //     var re=new RegExp(LIVE_URL); 
          //     var re1=new RegExp(RTMP); 
          //     if (!re1.test(value) || !re.test(value)) { 
          //       callback(new Error(t("1359")));
          //     }else if(/[\u4E00-\u9FA5]/g.test(value)){
          //       callback(new Error(t("1360")));
          //     }else { 
          //       callback();
          //     } 
          //   },
          //   trigger: "blur"
          // }
          {
            validator: function(rule, value, callback) {
              //校验中文的正则：/^[\u4e00-\u9fa5]{0,}$/
              if (/[\u4E00-\u9FA5]/g.test(value)) {
                callback(new Error(t("1360")));
              }else {
                //校验通过
                callback();
              }
            },
            trigger: "blur"
          },
        ],
        playPath: [
          { required: true, message: t("1358"), trigger: "change" },
          {
            validator: function(rule, value, callback) {
              //校验中文的正则：/^[\u4e00-\u9fa5]{0,}$/
              if (/[\u4E00-\u9FA5]/g.test(value)) {
                callback(new Error(t("1360")));
              }else {
                //校验通过
                callback();
              }
            },
            trigger: "blur"
          },
        ],
        liveName: [
          { required: true, message: t("1353"), trigger: "change" }
        ],
  
        
      },
      diffData: {} // 差异
    });

    onMounted(() => {
      if (dialogTitle.value === t("1146")) {
        state.streamingForm.sn = airPortSn.value;
      } else {
        state.streamingForm = JSON.parse(JSON.stringify(rowData.value));
      }
    });
    /* 工号检验 */
    const memberJobNumber = (rule, value, callback) => {
      if (value) {
        let strRegex = /^[0-9a-zA-Z]*$/;
        return !strRegex.test(value)
          ? callback(new Error(t(`工号只能是字母或者数字`)))
          : callback();
      } else {
        callback();
      }
    };
    const elForms = ref(null);
    const methods = reactive({
      sava: () => {
        elForms.value.validate(async valid => {
          if (valid) {
            let submit = {};
            submit.liveName = state.streamingForm.liveName;
            submit.sn = state.streamingForm.sn;
            submit.liveRtmpPath = state.streamingForm.liveRtmpPath;
            submit.playPath = state.streamingForm.playPath;
            if (dialogTitle.value === t("1146")) {
              let res = await device.addStreaming(submit);
              if (res.resultStatus) {
                ElMessage.success(t("713"));
                emit("handleStreamingEd");
              }
            } else {
              methods.diffFormData();
              if (Object.keys(state.diffData).length == 0) {
                ElMessage.info(t("1361"));
              } else {
                state.diffData.id = state.streamingForm.id;
                let res = await device.editStreaming(state.diffData);
                if (res.resultStatus) {
                  ElMessage.success(t("714"));
                  emit("handleStreamingEd");
                }
              }
            }
          } else {
            return false;
          }
        });
      },
      // 比较差异的方法
      diffFormData() {
        state.diffData = {};
        for (let k in rowData.value) {
          if (rowData.value[k] !== state.streamingForm[k]) {
            state.diffData[k] = state.streamingForm[k];
          }
        }
      },
      //输入推流路径改变或者失去焦点时
      pushFlowChange: (e) =>{
        console.log(state.streamingForm.playPath);
        if (state.streamingForm.playPath === '') {
          state.streamingForm.playPath = e
        }
      }
    });

    return {
      ...toRefs(state),
      ...toRefs(methods),
      elForms
    };
  }
});
</script>

<style lang="less" scoped>
.sampe{
  color: #dee1e6;
  font-size: 0.75rem;
}
/deep/.el-dialog__header .el-dialog__title {
  color: #ffffff !important;
}
/deep/.el-form-item {
  margin-bottom: 2rem;
}
/deep/.el-form-item .el-form-item__label {
  margin-bottom: 0.25rem !important;
  color: #ffffff;
}
/deep/.el-dialog__close {
  color: #ffffff !important;
}
/deep/.el-form--default.el-form--label-top .el-form-item .el-form-item__label {
  margin-bottom: 0;
}
.editbox {
  margin-bottom: 2.5rem;
}
.left {
  width: 20rem;
}
.right {
  width: 20rem;
}
/deep/.common-input .el-input__wrapper {
  background: #001c31 !important;
}
/deep/ .el-input__wrapper {
  background: #001c31 !important;
  height: 2.5rem !important;
  border-radius: 0 !important;
}
/deep/ .el-input__inner {
  color: #ffffff;
  height: 2.5rem !important;
}
.select {
  position: relative;
  width: 100%;
  height: 2.5rem;
  background: #001c31;
  box-sizing: border-box;
  border: 1px solid #51d2ff;
  // border-radius: 4px;
  padding: 0.8125rem 0.75rem;
  span {
    color: #a9b8c3;
  }
  .active_span {
    color: #ffffff;
  }
  .select_icon {
    color: #a9b8c3;
  }
  ul {
    position: absolute;
    top: 2.5rem;
    left: 0;
    width: 100%;
    height: 10.75rem;
    background: #ffffff;
    border-radius: 0.25rem;
    z-index: 33;
    .libox {
      width: 100%;
      height: 10.6875rem;
      overflow: hidden;
      overflow-y: auto;
    }
    li {
      height: 2.1875rem;
      line-height: 2.1875rem;
      padding: 0.3125rem 0.6875rem 0.1875rem 1.875rem;
      color: #666666;
      cursor: pointer;
      box-sizing: border-box;
    }
    .active {
      color: #138fcf;
    }
    .in_border {
      box-shadow: 0 0 0 1px #138fcf inset;
      // border: .0625rem solid #138FCF;
    }
    input {
      height: 100%;
      width: 12.5rem;
      outline: 0;
      box-sizing: border-box;
      color: #333333;
      border: 0;
      background: #ffffff;
    }
    input::-webkit-input-placeholder {
      color: #a9b8c3;
    }
    .addbox {
      padding: 0.3125rem 0 0.3125rem 0;
      .add_right {
        font-size: 0.75rem;
        width: 3.5rem;
        height: 2.1875rem;
        line-height: 2.1875rem;
        text-align: center;
        color: #138fcf;
        z-index: 22;
        margin-right: 0.3125rem;
        cursor: pointer;
      }
    }
    .addshow {
      width: 100%;
      margin-left: 1.875rem;
      height: 2.1875rem;
      line-height: 2.1875rem;
      padding: 0 0 0 0.3125rem;
      z-index: 22;
      box-sizing: border-box;
      // border-radius: 0 !important;
    }
    .select_edit {
      font-size: 0.9375rem;
      color: #138fcf;
      margin-right: 1.1875rem;
    }
    .element_icon {
      font-size: 0.9375rem;
      color: red;
    }
    .select_btn {
      width: 100%;
      height: 40px;
      background: #138fcf;
      border-radius: 0px 0px 4px 4px;
      img {
        height: 1.125rem;
        width: 1.125rem;
        margin-right: 0.4375rem;
      }
      .select_add {
        background: #ffffff;
      }
    }
  }
}
</style>
