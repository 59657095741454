<template>
  <ele-dialog
    :isVisible="isVisible"
    :dialogTitle="'推流设置'"
    @on-cancel="closeIsVisible"
    :hasFooter="false"
    :dialogWidth="'78.5rem'"
  >
    <div style="position:relative">
      <div style="margin-bottom: 1.53846rem;">
        <el-button type="primary" @click="addStreaming">{{ t('209') }}</el-button>
        <el-button @click="delStreaming">{{ t('210') }}</el-button>
      </div>
      <div>
        <ele-table
          :tableColumnArray="tableColumnArray"
          :tableData="tableData"
          :fullHeight="'65vh'"
          :notShowingPagination="true"
          @handleSizeChange="getStreamingList"
          @handleSelectionChange="handleSelectionChange"
        >
          <template v-slot:buttonHanlder>
            <el-table-column :label="t('610')" align="center">
              <template v-slot="{ row }">
                <el-row>
                  <el-col :span="21">
                    <span class="blue_btn" @click.stop="editStreaming(row)">{{ t('674') }}</span>
                  </el-col>
                </el-row>
              </template>
            </el-table-column>
          </template>
        </ele-table>
      </div>
    </div>
    <streamingHandle
      v-if="handleStreamingShow"
      :isVisible="handleStreamingShow"
      :dialogTitle="handleStreamingTitle"
      :airPortSn="airPortSn"
      :rowData="streamingForm"
      @handleStreamingEd="handleStreamingEd"
    ></streamingHandle>
    <!-- 删除 -->
    <DelDialog
      v-if="delShow"
      :dialogTitle="delDialogTitle"
      :isVisible="delShow"
      @on-add="delSubmit"
      @on-cancel="airCancel"
    >
      <div>
        <span class="del">!</span> {{ t('1145') }}？
      </div>
    </DelDialog>
  </ele-dialog>
</template>

<script>
import { t } from '../../../languages';
import {
  defineComponent,
  onMounted,
  reactive,
  watchEffect,
  toRefs,
  ref,
  nextTick
} from "vue";
import streamingHandle from "./components/streaming-handle.vue";
import device from "@/network/device";
import DelDialog from "../../../components/package/ele-dialog.vue";
import { ElMessage } from "element-plus";
export default defineComponent({
  components: {
    streamingHandle,
    DelDialog
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false
    },
    dialogTitle: {
      type: String,
      default: () => {}
    },
    airPortSn: {
      type: String,
      default: () => {}
    }
  },
  emits: ["closeIsVisible"],
  setup(props, { emit }) {
    const state = reactive({
      delDialogTitle: t("130"),
      delShow: false,
      //推流操作弹窗
      handleStreamingShow: false,
      handleStreamingTitle: t("1146"),
      streamingForm: {},
      // 分页
      queryForm: {
        modelId: "",
        queryInfo: "",
        region: "",
        teamId: "",
        type: "AIRPORT",
        pageNo: 1,
        pageSize: 10
      },
      // 表头
      tableColumnArray: [
        { label: t("1147"), prop: "liveName" },
        { label: t("1148"), prop: "liveRtmpPath",   },
        // { label: t("1149"), prop: "flvPort" },
        // { label: t("1150"), prop: "rtcPort" },
        // { label: t("1151"), prop: "rtcApi" }
      ],
      // 选中删除数据
      delArr: [],
      // 表体数据
      tableData: [],
      //单选多选删除ids集合
      ids: [],
      airPortSnHandle:''
    });
    onMounted(() => {
      methods.getStreamingList();
    });
    const { airPortSn } = toRefs(props);
    const methods = reactive({
      closeIsVisible: () => {
        emit("closeIsVisible");
      },
      // 多选确认删除
      delSubmit: async () => {
        let ids = state.ids;
        let res = await device.delStreaming({ ids });
        if (res.resultStatus) {
          ElMessage.success(t("560"));
          methods.getStreamingList();
          state.delShow = false;
          // state.queryForm.pageNo = 1;
          // state.queryForm.pageSize = 10;
        }
      },
      airCancel: () => {
        state.delShow = false;
      },
      // 新增刷新页面
      handleStreamingEd: () => {
        state.handleStreamingShow = false;
        methods.getStreamingList();
      },
      //增加推流
      addStreaming: async () => {
        state.handleStreamingShow = true;
        state.handleStreamingTitle = t("1146");
      },
      //删除推流
      delStreaming: async () => {
        if (state.delArr == "") {
          ElMessage.warning(t("620"));
        } else {
          state.ids = []; //去除上一次数据
          state.delArr.map(item => state.ids.push(item.id));
          state.delShow = true;
        }
      },
      // 编辑推流
      editStreaming: async row => {
        state.streamingForm = row;
        state.handleStreamingShow = true;
        state.handleStreamingTitle = t("674");
      },
      //默认数据列表
      getStreamingList: async () => {
        let res = await device.queryStreamingList(airPortSn.value);
        if (res.resultStatus) {
          state.tableData = res.resultData;
          state.queryForm.total = res.resultData.total;
        }else{
          emit("closeIsVisible");
        }
      },
      // 多选事件
      handleSelectionChange: e => {
        state.delArr = e;
      }
    });

    return {
      ...toRefs(state),
      ...toRefs(methods)
    };
  }
});
</script>

<style>
/* 去除校验边框 */
.el-form-item.is-error .el-input__wrapper {
  box-shadow: 0 0 0 0 #f56c6c inset !important;
}
</style>
<style lang="less" scoped>
:deep(.el-col-21){
  flex: 100%;
  max-width: 100%;
}
/deep/.el-dialog__header .el-dialog__title {
  color: #ffffff !important;
}
/deep/.el-form-item {
  margin-bottom: 2rem;
}
/deep/.el-form-item .el-form-item__label {
  margin-bottom: 0.25rem !important;
  color: #ffffff;
}
/deep/.el-dialog__close {
  color: #ffffff !important;
}
/deep/.el-form--default.el-form--label-top .el-form-item .el-form-item__label {
  margin-bottom: 0;
}
.editbox {
  margin-bottom: 2.5rem;
}
.left {
  width: 20rem;
}
.right {
  width: 20rem;
}
/deep/.common-input .el-input__wrapper {
  background: #001c31 !important;
}
/deep/ .el-input__wrapper {
  background: #001c31 !important;
  height: 2.5rem !important;
  border-radius: 0 !important;
}
/deep/ .el-input__inner {
  color: #ffffff;
  height: 2.5rem !important;
}
.select {
  position: relative;
  width: 100%;
  height: 2.5rem;
  background: #001c31;
  box-sizing: border-box;
  border: 1px solid #51d2ff;
  // border-radius: 4px;
  padding: 0.8125rem 0.75rem;
  span {
    color: #a9b8c3;
  }
  .active_span {
    color: #ffffff;
  }
  .select_icon {
    color: #a9b8c3;
  }
  ul {
    position: absolute;
    top: 2.5rem;
    left: 0;
    width: 100%;
    height: 10.75rem;
    background: #ffffff;
    border-radius: 0.25rem;
    z-index: 33;
    .libox {
      width: 100%;
      height: 10.6875rem;
      overflow: hidden;
      overflow-y: auto;
    }
    li {
      height: 2.1875rem;
      line-height: 2.1875rem;
      padding: 0.3125rem 0.6875rem 0.1875rem 1.875rem;
      color: #666666;
      cursor: pointer;
      box-sizing: border-box;
    }
    .active {
      color: #138fcf;
    }
    .in_border {
      box-shadow: 0 0 0 1px #138fcf inset;
      // border: .0625rem solid #138FCF;
    }
    input {
      height: 100%;
      width: 12.5rem;
      outline: 0;
      box-sizing: border-box;
      color: #333333;
      border: 0;
      background: #ffffff;
    }
    input::-webkit-input-placeholder {
      color: #a9b8c3;
    }
    .addbox {
      padding: 0.3125rem 0 0.3125rem 0;
      .add_right {
        font-size: 0.75rem;
        width: 3.5rem;
        height: 2.1875rem;
        line-height: 2.1875rem;
        text-align: center;
        color: #138fcf;
        z-index: 22;
        margin-right: 0.3125rem;
        cursor: pointer;
      }
    }
    .addshow {
      width: 100%;
      margin-left: 1.875rem;
      height: 2.1875rem;
      line-height: 2.1875rem;
      padding: 0 0 0 0.3125rem;
      z-index: 22;
      box-sizing: border-box;
      // border-radius: 0 !important;
    }
    .select_edit {
      font-size: 0.9375rem;
      color: #138fcf;
      margin-right: 1.1875rem;
    }
    .element_icon {
      font-size: 0.9375rem;
      color: red;
    }
    .select_btn {
      width: 100%;
      height: 40px;
      background: #138fcf;
      border-radius: 0px 0px 4px 4px;
      img {
        height: 1.125rem;
        width: 1.125rem;
        margin-right: 0.4375rem;
      }
      .select_add {
        background: #ffffff;
      }
    }
  }
}
</style>
