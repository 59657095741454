<template>
  <div class="container">
    <!-- 头部 -->
    <div class="header">
      <DeviceHeader
        :navlist="navlist"
        :dialogType="dialogType"
        @addUAV="addUAV"
        @deviceDel="deviceDel"
        @searchChange="searchChange"
      />
    </div>
    <div class="elTab">
      <ele-table
        :tableColumnArray="tableColumnArray"
        :tableData="tableData"
        v-model:pageNo="queryForm.pageNo"
        v-model:pageSize="queryForm.pageSize"
        v-model:total="queryForm.total"
        @handleNoChange="getAirportList"
        @handleSizeChange="getAirportList"
        @handleSelectionChange="handleSelectionChange"
      >
        <template v-slot:buttonHanlder>
          <el-table-column :label="t('610')" align="center" width="200px">
            <template v-slot="{ row, index }">
              <el-row>
                <!-- <el-col :span="8">
                  <span class="blue_btn" @click.stop="streaming(row)">{{ t('611') }}</span>
                </el-col> -->
                <el-col :span="8">
                  <span class="blue_btn" @click.stop="detail(row)">{{ t('391') }}</span>
                </el-col>
                <el-col :span="10">
                  <span
                    class="blue_btn"
                    :class="row.open == true ? 'open' : ''"
                    @click.stop="openRecord(row, index)"
                    >{{ t('612') }}</span
                  >
                </el-col>
                <!-- <el-col :span="6">
                  <el-icon class="blue_btn"><MoreFilled /></el-icon>
                </el-col> -->
                <el-col :span="6">
                  <el-dropdown>
                    <span class="el-dropdown-link">
                      <el-icon
                        class="blue_btn"
                        style="position: absolute; top: 5px"
                        ><MoreFilled
                      /></el-icon>
                    </span>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <el-dropdown-item @click.stop="streaming(row)"
                          >{{ t('611') }}</el-dropdown-item
                        >
                        <el-dropdown-item @click.stop="logManagement(row)"
                          >{{ t('613') }}</el-dropdown-item
                        >
                        <el-dropdown-item @click="jumpAirportVersion(row)"
                          >{{ t('167') }}</el-dropdown-item
                        >
                        <el-dropdown-item @click="jumpPlatforms(row)"
                          >{{ t('平台对接') }}</el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </el-col>
              </el-row>
            </template>
          </el-table-column>
        </template>
      </ele-table>
    </div>

    <!-- 详情 -->
    <Detail
      v-if="showDetail"
      :editShow="editShow"
      :dialogType="dialogType"
      @closeDetail="closeDetail"
      @edit="edit"
      @editClose="editClose"
      @editCommit="editCommit"
      :detailData="detailData"
    />
  </div>
  <!-- 添加弹窗 -->
  <AddDevice
    v-if="addDeviceShow"
    v-model:isVisible="addDeviceShow"
    :editTitle="editTitle"
    :dialogType="dialogType"
    @addDevice="addDevice"
  />
  <!-- 推流设置弹窗 -->
  <StreamingSettings
    v-if="addStreamingShow"
    :isVisible="addStreamingShow"
    :dialogTitle="StreamingTitle"
    :airPortSn="airPortSn"
    @closeIsVisible="closeIsVisible"
  />
  <!-- 日志管理弹窗 -->
  <logManagementDialog
    v-if="logManagementShow"
    :isVisible="logManagementShow"
    :dialogTitle="logManagementTitle"
    :airPortSn="airPortSn"
    @closeIsVisible="closeLogManagement"
  />
  <!-- 删除 -->
  <DelDialog
    v-if="delShow"
    :dialogTitle="delDialogTitle"
    :isVisible="delShow"
    @on-add="delSubmit"
    @on-cancel="airCancel"
  >
    <div><span class="del">!</span> {{ t('614') }}？</div>
  </DelDialog>

  <!-- 授权 -->
  <dockingPlatforms
  v-if="authOpen"
  :isVisible="authOpen"
  :authSn="authSn"
  @closeAuth="closeAuthOpen"
  >
  </dockingPlatforms>
</template>
<script>
import { t } from '../../languages';
import { defineComponent, onMounted, reactive, toRefs } from "vue";
import DeviceHeader from "../../components/common/device-header.vue";
import AddDevice from "./components/add-device.vue";
import StreamingSettings from "./components/streaming-settings.vue";
import logManagementDialog from "./components/log-management.vue";
import Detail from "./components/detail.vue";
import DelDialog from "../../components/package/ele-dialog.vue";
import device from "../../network/device";
import { ElMessage } from "element-plus";
import { useRouter } from "vue-router";
import dockingPlatforms from "./components/docking-platforms.vue";
export default defineComponent({
  components: {
    DeviceHeader,
    AddDevice,
    Detail,
    DelDialog,
    StreamingSettings,
    logManagementDialog,
    dockingPlatforms
  },
  setup() {
    const router = useRouter();
    const state = reactive({
      navlist: [
        { id: 1, name: t("164"), path: "/airport", type: "airport" },
        // { id: 2, name: t("165"), path: "/deviceManageIndex", type: "fly" },
        { id: 3, name: t("166"), path: "/otherAsset", type: "asset" },
      ],
      // 详情编辑
      editShow: false,
      //
      openShow: false,
      // 详情
      showDetail: false,
      detailData: {},
      // 添加
      addDeviceShow: false,
      //推流设置弹窗打开显示
      addStreamingShow: false,
      logManagementShow: false,
      StreamingTitle: t("611"),
      logManagementTitle: t("613"),
      editTitle: t("615"),
      dialogType: "airport",
      delShow: false,
      delDialogTitle: t("130"),
      // 分页
      queryForm: {
        modelId: "",
        queryInfo: "",
        region: "",
        teamId: "",
        type: "AIRPORT",
        pageNo: 1,
        pageSize: 10,
      },
      // 表头
      tableColumnArray: [
        { label: t("616"), prop: "deviceName" },
        { label: t("617"), prop: "sn" },
        { label: t("215"), prop: "model" },
        { label: t("618"), prop: "number" },
        { label: t("212"), prop: "region" },
        { label: t("214"), prop: "teamName" },
        { label: t("619"), prop: "taskNumber" },
      ],
      // 表体数据
      tableData: [],
      // 作业信息
      jobInformation: [{}],
      //单选多选删除ids集合
      ids: [],
      // 选中删除数据
      delArr: [],
      //进入推流设置弹窗的机场SN
      airPortSn: "",
      authOpen:false,
      authSn:""
    });
    onMounted(() => {
      methods.getAirportList();
    });
    const methods = reactive({
      //推流设置弹窗打开
      streaming: (row) => {
        if (!state.addStreamingShow) {
          state.addStreamingShow = true;
          state.airPortSn = row.sn;
        }
      },
      //日志管理
      logManagement: (row) => {
        if (!state.logManagementShow) {
          state.logManagementShow = true;
          state.airPortSn = row.sn;
        }
      },
      closeIsVisible: () => {
        state.addStreamingShow = false;
      },
      closeLogManagement: () => {
        state.logManagementShow = false;
      },
      // 打开详情页
      detail: (row) => {
        state.detailData = row;
        state.showDetail = true;
      },
      // 关闭
      closeDetail: () => {
        state.showDetail = false;
        state.editShow = false;
      },
      // 开启录像
      openRecord: (row, index) => {
        console.log(row);
        console.log(index);
        state.openShow = !state.openShow;
        state.tableData.open = !state.tableData.open;
      },
      // 详情编辑
      edit: () => {
        state.editShow = !state.editShow;
      },
      // 关闭编辑
      editClose: () => {
        state.editShow = false;
      },
      // 提交编辑数据
      editCommit: () => {
        state.editShow = false;
        methods.getAirportList();
      },
      // 添加数据
      addUAV: () => {
        state.addDeviceShow = !state.addDeviceShow;
      },
      // 删除
      deviceDel: () => {
        if (state.delArr == "") {
          ElMessage.warning(t("620"));
        } else {
          state.ids = []; //去除上一次数据
          state.delArr.map((item) => state.ids.push(item.id));
          state.delShow = true;
        }
      },
      // 多选确认删除
      delSubmit: async () => {
        let ids = state.ids;
        let res = await device.delDevice({ ids });
        if (res.resultStatus) {
          ElMessage.success(t("560"));
          methods.getAirportList();
          state.delShow = false;
          // state.queryForm.pageNo = 1;
          // state.queryForm.pageSize = 10;
        }
      },
      airCancel: () => {
        state.delShow = false;
      },
      // 头部搜索数据
      searchChange: (e) => {
        state.queryForm = { ...e };
        methods.getAirportList();
      },
      //默认数据列表
      getAirportList: async () => {
        let res = await device.getDeviceList(state.queryForm);
        if (res.resultStatus) {
          state.tableData = res.resultData.data;
          state.queryForm.total = res.resultData.total;
        }
      },
      // 新增刷新页面
      addDevice: () => {
        state.addDeviceShow = false;
        methods.getAirportList();
      },
      // 多选事件
      handleSelectionChange: (e) => {
        state.delArr = e;
      },
      jumpAirportVersion: (rowData) => {
        console.log(rowData);
        router.push({
          path: "/airportVersion",
          query: {
            sn: rowData.sn,
          },
        });
      },
      jumpPlatforms:(row)=>{
        state.authOpen = true;
        state.authSn= row.sn;
      },
      closeAuthOpen:()=>{
        state.authOpen = false;
      },
    });
    return {
      ...toRefs(state),
      ...toRefs(methods),
    };
  },
});
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  height: 100%;
  background: rgba(0, 28, 49, 0.8);
  /deep/.el-table tr {
    background-color: none !important;
  }
}
.header {
  height: 7.125rem;
}
.elTab {
  padding: 0 2.0625rem;
  box-sizing: border-box;
  height: calc(100% - 7.125rem);
  background: rgba(0, 28, 49, 0.8);
  /deep/ .el-table tr {
    background-color: none !important;
  }
  /deep/ .el-table__body {
    box-sizing: border-box;
    overflow: hidden;
    width: 100% !important;
  }
  //body边距
  /deep/ .el-table__row {
    height: 3.75rem !important;
    border-radius: 0 8px 8px 0 !important;
  }
  /deep/tr .el-table__row {
    border-radius: 0 8px 8px 0 !important;
  }
  /deep/ .table-wrapper {
    position: initial;
  }
  // 分页
  /deep/ .el_pageination {
    right: 2.125rem;
  }
  /deep/ .el_pageination .el-input_wrapper {
    background: rgba(0, 28, 49, 0.8) !important;
  }
  //下拉背景图片
  /deep/ .el-select .el-input__wrapper {
    background: rgba(0, 28, 49, 0.8) !important;
  }
  /deep/ .el-input--small .el-input__wrapper {
    background: rgba(0, 28, 49, 0.8) !important;
  }
}
.del {
  display: inline-block;
  background: #e6a33d;
  color: #ffffff;
  width: 1.625rem;
  height: 1.625rem;
  line-height: 1.625rem;
  text-align: center;
  border-radius: 50%;
  margin-right: 0.9375rem;
}
</style>