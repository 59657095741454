<template>
  <ele-dialog
    :isVisible="isVisible"
    :dialogTitle="t('613')"
    @on-cancel="closeIsVisible"
    :hasFooter="false"
    :dialogWidth="'78.5rem'"
  >
    <div>
    <div id="topContent">
      <div class="selet_type">
        <label  class="label" >{{ t('1137') }} </label>
        <el-select v-model="selectType.selectVal" class="m-2" clearable  :placeholder="t('175')" size="small" @change="onSelect">
            <el-option
            v-for="item in typeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
            />
        </el-select>
      </div>
      <div style="margin-bottom: 1.53846rem;">
        <el-button type="primary" @click="queryLog">{{ t('1138') }}</el-button>
        <el-button @click="delLog">{{ t('210') }}</el-button>
      </div>
      </div>
      <div>
        <ele-table
          :tableColumnArray="tableColumnArray"
          :tableData="tableData"
          :fullHeight="'65vh'"
          v-model:pageNo="queryForm.pageNo"
          v-model:pageSize="queryForm.pageSize"
          :total="queryForm.total"
          @handleSizeChange="getLogList"
          @handleNoChange="getLogList"
          @handleSelectionChange="handleSelectionChange"
          v-loading="loading"
          :element-loading-text="LoadingText"
          element-loading-svg-view-box="-10, -10, 50, 50"
          element-loading-background="rgba(0, 67, 117, 0.6)"
        >
          <template v-slot:buttonHanlder>
            <el-table-column :label="t('610')" align="center">
              <template v-slot="{ row }">
                <el-row>
                  <el-col :span="24">
                    <span class="blue_btn" @click.stop="downLog(row)">{{ t('393') }}</span>
                  </el-col>
                </el-row>
              </template>
            </el-table-column>
          </template>
        </ele-table>
      </div>
    </div>
    <queryLog
      v-if="handleQueryLogShow"
      :isVisible="handleQueryLogShow"
      :dialogTitle="handleStreamingTitle"
      @handleQueryLogEd="queryLogEd"
      @closeQueryLogEd="handleQueryLogShow = false"
    ></queryLog>
    <!-- 删除 -->
    <DelDialog
      v-if="delShow"
      :dialogTitle="delDialogTitle"
      :isVisible="delShow"
      @on-add="delSubmit"
      @on-cancel="airCancel"
    >
      <div>
        <span class="del">!</span> {{ t('1139') }}？
      </div>
    </DelDialog>
  </ele-dialog>
</template>

<script>
import { t } from '../../../languages';
import {
  defineComponent,
  onMounted,
  onBeforeUnmount,
  reactive,
  watchEffect,
  toRefs,
  ref,
  nextTick
} from "vue";
import queryLog from "./components/query-log.vue";
import device from "@/network/device";
import downListExcel from "../../../network/download";
import DelDialog from "../../../components/package/ele-dialog.vue";
import { ElMessage } from "element-plus";
import {  ElNotification } from 'element-plus';
import emitter from '@/utils/mitt';
import { store } from "@/store";
import { number } from "echarts";
export default defineComponent({
  components: {
    queryLog,
    DelDialog
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false
    },
    dialogTitle: {
      type: String,
      default: () => {}
    },
    airPortSn: {
      type: String,
      default: () => {}
    }
  },
  emits: ["closeIsVisible"],
  setup(props, { emit }) {
    const state = reactive({
      delDialogTitle: t("130"),
      delShow: false,
      //推流操作弹窗
      handleQueryLogShow: false,
      handleStreamingTitle: t("1138"),
      streamingForm: {},
      // 分页
      queryForm: {
        nestName:'',
        nestSn: "",
        firmwareType: 0,
        pageNo: 1,
        pageSize: 10,
      },
      // 表头
      tableColumnArray: [
        { label: t("606"), prop: "nestName" ,width:'120' },
        { label: t("594"), prop: "firmwareType",width:'100' },
        { label: t("1140"), prop: "createTime" ,width:'225'},
        { label: t("799"), prop: "startTime"  ,width:'145'},
        { label: t("800"), prop: "endTime" }
      ],
      // 选中删除数据
      delArr: [],
      // 表体数据
      tableData: [],
      //单选多选删除ids集合
      ids: [],
      airPortSnHandle:'',
      typeList:[
      {
        value: '0',
        label: t('164'),
      },
      {
        value: '1',
        label: t('595'),
      },
      {
        value: '2',
        label: t('596'),
      },
      ],
      selectType:{
        selectVal:''
      },
      loading:false,
      LoadingText:t('1141')
    });
    onMounted(() => {
      methods.queryTypeList()
      methods.getLogList();
    });
    emitter.on('queryLogSuccess', dataInfo => {
      if (dataInfo.data.msg === t('1142')) {
        ElMessage.success(dataInfo.data.msg)
        methods.getLogList(true)
      }else{
        ElMessage.error(dataInfo.data.msg)
      }
      state.selectType.selectVal = ''
      state.loading = false;
    })
    onBeforeUnmount(() => {
    emitter.off('queryLogSuccess');
    })
    const { airPortSn } = toRefs(props);
    const methods = reactive({
      //获取类型列表
      queryTypeList: async () => {
          return
          let res = await device.delStreaming({ ids });
          if (res.resultStatus) {
           
          }
        },
      closeIsVisible: () => {
        emit("closeIsVisible");
        state.loading = false;
      },
      // 多选确认删除
      delSubmit: async () => {
        let ids = state.ids;
        let res = await device.deleteLog({ ids });
        if (res.resultStatus) {
          ElMessage.success(t("560"));
          methods.getLogList();
          state.delShow = false;
        }
      },
      airCancel: () => {
        state.delShow = false;
      },
      // 下拉选择
      onSelect: (value) => {
        methods.getLogList(true)
      },
      
      //获取日志
      queryLog: async () => {
        state.handleQueryLogShow = true;
      },
      //获取完成之后
      queryLogEd: async (data) => {
          //获取用户id
        let createUserId = store.state.user.userInfo.id || JSON.parse(localStorage.getItem("userInfo")).id;
        let queryFormEd = {}
        if (data.time.length !== 0) {
          queryFormEd.startDateTime = data.time[0]
          queryFormEd.endDateTime = data.time[1]
        }else{
          queryFormEd.startDateTime = ''
          queryFormEd.endDateTime = ''
        }
        queryFormEd.firmwareType = Number(data.type)
        queryFormEd.sn = airPortSn.value
        queryFormEd.userId = createUserId
        queryFormEd.uploadUrl = ''
        let res = await device.queryLogBtn(queryFormEd);
        if (res.resultStatus) {
          if (queryFormEd.firmwareType !== 0) {
            state.LoadingText = t('1143')
            state.loading = false;
            ElMessage.info(t('1144'))
          }else{
            state.handleQueryLogShow = false;
            state.loading = true;
            state.LoadingText = t('1141')
          }
        }
        setTimeout(() => {
          state.loading = false;
        }, 2000);
      },
      //删除日志
      delLog: async () => {
        if (state.delArr == "") {
          ElMessage.warning(t("620"));
        } else {
          state.ids = []; //去除上一次数据
          state.delArr.map(item => state.ids.push(item.id));
          state.delShow = true;
        }
      },
      // 下载日志
      downLog: async row => {
        downListExcel.downloadSource(
          `/api/v1/appLog/downLoadLog/${row.id}`,
            "application/zip",
            "post"
        )
      },
      //默认数据列表
      getLogList: async (flag) => {
        if (flag) {
          state.queryForm.pageNo = 1;
        }
        state.queryForm.firmwareType = Number(state.selectType.selectVal)
        state.queryForm.nestSn = airPortSn.value
        let res = await device.queryLog(
          {
          nestName:'',
          firmwareType:state.queryForm.firmwareType,
          nestSn:state.queryForm.nestSn,
          pageSize:state.queryForm.pageSize,
          pageNo:state.queryForm.pageNo,
          }
        );
        if (res.resultStatus && res.resultData.data.length !== 0) {
          res.resultData.data.forEach(e => {
            if(e.firmwareType === 0){
              e.firmwareType = t('164')
            }else if(e.firmwareType === 0){
              e.firmwareType = t('595')
            }else{
              e.firmwareType = t('596')
            }
          });
        }
        state.tableData = []
        state.tableData = res.resultData.data
        state.queryForm.total = res.resultData.total;
      },
      // 多选事件
      handleSelectionChange: e => {
        state.delArr = e;
      }
    });

    return {
      ...toRefs(state),
      ...toRefs(methods)
    };
  }
});
</script>

<style>

/* 去除校验边框 */
.el-form-item.is-error .el-input__wrapper {
  box-shadow: 0 0 0 0 #f56c6c inset !important;
}
</style>
<style lang="less" scoped>
/deep/.el-button{
  height: 2.5rem;
}
.selet_type{
  margin-bottom: 1.53846rem;
  margin-left: -2.75rem; 
  display: flex;
  align-items: center;
}
#topContent{
  display:flex;
  justify-content:space-between
}
.label {
    font-size: 1rem;
    color: #36e5ff;
    margin-right: 0.75rem;
    margin-left: 2.75rem;
  }
 /deep/ #topContent .el-input__wrapper {
      border: 1px solid #36e5ff;
      border-radius: 0.25rem;
      background-color: @lightBackground;
      height: 2.5rem;
      box-shadow: 0 0 0 0;
      .el-input__inner {
        color: #fff;
      }
    }
/deep/.el-dialog__header .el-dialog__title {
  color: #ffffff !important;
}
/deep/.el-form-item {
  margin-bottom: 2rem;
}
/deep/.el-form-item .el-form-item__label {
  margin-bottom: 0.25rem !important;
  color: #ffffff;
}
/deep/.el-dialog__close {
  color: #ffffff !important;
}
/deep/.el-form--default.el-form--label-top .el-form-item .el-form-item__label {
  margin-bottom: 0;
}
.editbox {
  margin-bottom: 2.5rem;
}
.left {
  width: 20rem;
}
.right {
  width: 20rem;
}
/deep/.common-input .el-input__wrapper {
  background: #001c31 !important;
}
/deep/#topContent .el-input__wrapper {
  background: #001c31 !important;
  height: 2.5rem !important;
  border-radius: 0 !important;
}
/deep/#topContent .el-input__inner {
  color: #ffffff;
  height: 2.5rem !important;
}
.select {
  position: relative;
  width: 100%;
  height: 2.5rem;
  background: #001c31;
  box-sizing: border-box;
  border: 1px solid #51d2ff;
  // border-radius: 4px;
  padding: 0.8125rem 0.75rem;
  span {
    color: #a9b8c3;
  }
  .active_span {
    color: #ffffff;
  }
  .select_icon {
    color: #a9b8c3;
  }
  ul {
    position: absolute;
    top: 2.5rem;
    left: 0;
    width: 100%;
    height: 10.75rem;
    background: #ffffff;
    border-radius: 0.25rem;
    z-index: 33;
    .libox {
      width: 100%;
      height: 10.6875rem;
      overflow: hidden;
      overflow-y: auto;
    }
    li {
      height: 2.1875rem;
      line-height: 2.1875rem;
      padding: 0.3125rem 0.6875rem 0.1875rem 1.875rem;
      color: #666666;
      cursor: pointer;
      box-sizing: border-box;
    }
    .active {
      color: #138fcf;
    }
    .in_border {
      box-shadow: 0 0 0 1px #138fcf inset;
      // border: .0625rem solid #138FCF;
    }
    input {
      height: 100%;
      width: 12.5rem;
      outline: 0;
      box-sizing: border-box;
      color: #333333;
      border: 0;
      background: #ffffff;
    }
    input::-webkit-input-placeholder {
      color: #a9b8c3;
    }
    .addbox {
      padding: 0.3125rem 0 0.3125rem 0;
      .add_right {
        font-size: 0.75rem;
        width: 3.5rem;
        height: 2.1875rem;
        line-height: 2.1875rem;
        text-align: center;
        color: #138fcf;
        z-index: 22;
        margin-right: 0.3125rem;
        cursor: pointer;
      }
    }
    .addshow {
      width: 100%;
      margin-left: 1.875rem;
      height: 2.1875rem;
      line-height: 2.1875rem;
      padding: 0 0 0 0.3125rem;
      z-index: 22;
      box-sizing: border-box;
      // border-radius: 0 !important;
    }
    .select_edit {
      font-size: 0.9375rem;
      color: #138fcf;
      margin-right: 1.1875rem;
    }
    .element_icon {
      font-size: 0.9375rem;
      color: red;
    }
    .select_btn {
      width: 100%;
      height: 40px;
      background: #138fcf;
      border-radius: 0px 0px 4px 4px;
      img {
        height: 1.125rem;
        width: 1.125rem;
        margin-right: 0.4375rem;
      }
      .select_add {
        background: #ffffff;
      }
    }
  }
}
</style>
